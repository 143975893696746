import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RequestShippingPaymentService } from 'src/app/services/request-shipping-payment.service';
import { ListCarriersService } from 'src/app/services/list-carriers.service';
import { ListServicesService } from 'src/app/services/list-services.service';
import { ServiceService } from 'src/app/services/service.service';
import { ListDutyTaxesService } from 'src/app/services/list-duty-taxes.service';
import { CalculatorService } from 'src/app/services/calculator.service';
import { LanguageService } from '../services/language.service';
import { AlertMessageService } from '../services/alert-message.service';
import { MultilingualHeaderImageService } from '../services/multilingual-header-image.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-shared-calculator',
  templateUrl: './shared-calculator.component.html',
  styleUrls: ['./shared-calculator.component.scss']
})
export class SharedCalculatorComponent implements OnInit {
  countries: Array<any>;
  calculatorForm: FormGroup;
  carriers: Array<any>;
  dutytaxes: Array<any>;
  filterCarriers: Array<any>;
  services: Array<any> = [];
  language = this.languageService.getCurrentLanguage();
  result: any;
  countrySelected = true;
  defaultValue = '';
  defaultDutyTax: any;
  selectedService: any;
  weightUnit = 'lb';
  measure = 'inch';

  constructor(private languageService: LanguageService,
    private alertMessageService: AlertMessageService,
    private requestShippingPaymentService: RequestShippingPaymentService,
    private listCarriersService: ListCarriersService,
    private translate: TranslateService,
    private serviceService: ServiceService,
    private listDutyTaxes: ListDutyTaxesService,
    private multilingualHeadersService: MultilingualHeaderImageService,
    private calculatorService: CalculatorService) {
    this.weightUnit = localStorage.getItem('weightUnit');
    this.measure = localStorage.getItem('measure');
    this.getDutyTaxes();
    this.getCountries();
    this.calculatorForm = new FormGroup({
      country: new FormControl('', Validators.required),
      carrier: new FormControl({ value: '', disabled: this.countrySelected }, Validators.required),
      service: new FormControl({ value: '', disabled: this.countrySelected }, Validators.required),
      dutytax: new FormControl(''),
      weight: new FormControl('', [Validators.required, Validators.min(0)]),
      width: new FormControl('0', [Validators.required, Validators.min(0)]),
      height: new FormControl('0', [Validators.required, Validators.min(0)]),
      length: new FormControl('0', [Validators.required, Validators.min(0)]),
      declaredPrice: new FormControl('', [Validators.required, Validators.min(0)]),
      paymentMethod: new FormControl('', Validators.required)
    });
    this.languageService.initLanguageConfig();
    this.translate.use(this.languageService.getCurrentLanguage());
  }

  ngOnInit() {
  }

  getCountries() {
    const language = this.languageService.getCurrentLanguage();
    this.requestShippingPaymentService.getCountries().subscribe(result => {
      if (result['status'] === true) {
        this.countries = result['countries'];
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(language, messageCode);
    }
    );
  }

  getFlagURL(): string {
    const language = this.languageService.getCurrentLanguage();
    return this.languageService.getLanguageFlagURL(language);
  }

  // Método para cambiar el lenguaje de la aplicación
  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('language', language);
  }

  onChangeCountry($event) {
    this.countrySelected = false;
    this.calculatorForm.controls['carrier'].enable();
    this.calculatorForm.controls['service'].enable();
    this.getCarriers();
    this.getservices();


  }

  getCarriers() {
    this.listCarriersService.getCarriers().subscribe((result) => {
      if (result['status'] === true) {
        this.carriers = result['carriers'];
        this.filterCarriers = this.carriers.filter((w) => {
          if (w.country) {
            return w.country.id * 1 === this.calculatorForm.value['country'] * 1;
          } else {
            return false;
          }
        });
        // this.carriers.splice(0,1);
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(this.language, messageCode);
    });

  }

  getservices() {
    this.serviceService.getServicesForCalculator(this.calculatorForm.value['country']).subscribe((result) => {
      if (result['status'] === true) {
        this.services = result['services'];
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(this.language, messageCode);
    });
  }

  getDutyTaxes() {
    this.listDutyTaxes.getActiveDutyTaxes().subscribe((result) => {
      if (result['status'] === true) {
        this.dutytaxes = result['dutyTaxes'];
        this.defaultDutyTax = this.dutytaxes.find(x => x.isDefaultDutyTax === true);
        this.calculatorForm.controls['dutytax'].setValue(this.defaultDutyTax['id']);
      }
    }, error => {
      const messageCode = 'CE';
      this.alertMessageService.showErrorMessage(this.language, messageCode);
    });
  }

  calculate(data) {
    if (data.dutytax === '') {
      data.dutytax = this.defaultDutyTax['id']; // default value
    }
    this.calculatorService.getResult(data).subscribe((result) => {
      this.result = result;
      console.log(result);
    });
  }

  recalculate() {
    this.result = null;
  }

  getHeaderURL() {
    const language = this.languageService.getCurrentLanguage();
    return this.multilingualHeadersService.getHeaderURL('53', language);
  }


  getSelectedService() {
    for (const key in this.services) {
      const element = this.services[key];
      if (element.id == this.calculatorForm.value['service']) {
        this.selectedService = element;
      }
    }
  }
}

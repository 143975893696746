import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/services/theme.service';
import { CompanyInfoService } from 'src/app/services/company-info.service';
import { globalVariables } from 'src/app/shared/common/Constants';
import { AlertMessageService } from 'src/app/services/alert-message.service';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss']
})

export class FullLayoutComponent {
  constructor(private companyService: CompanyInfoService,
    private alertMessageService: AlertMessageService,
    private languageService: LanguageService,
    private themeService: ThemeService) {
    this.getCompanyInfo();
    this.setConfig();
  }

  getCompanyInfo() {
    if (localStorage.getItem('theme')) {
      this.themeService.applyTheme(localStorage.getItem('theme'));
    } else {
      this.themeService.getCompanyTheme();
    }
  }

  setConfig() {
    const language = this.languageService.getCurrentLanguage();
    this.companyService.getCompanyInfo().subscribe(result => {
      if (result['status']) {
        const company = result['company'];
        localStorage.setItem('weightUnit', company.weightUnit);
        localStorage.setItem('symbol', company.currencySymbol);
        localStorage.setItem('currency', company.currency);
        localStorage.setItem('measure', company.measureUnit);
      } else {
        this.alertMessageService.showErrorMessage(language, result['code']);
      }
    }, error => {
      this.alertMessageService.showErrorMessage(language, 'CE');
    });
  }

}
